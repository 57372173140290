import React, { useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../templates/main"
import SEO from "../components/seo"
import Hero from "../components/Organisms/Hero"
import LogoCompaniesSection from "../components/Organisms/LogoCompaniesSection"

const Contato = () => {
  const {
    mainBanner: {
      childImageSharp: { fluid: mainBanner },
    }
  } = useStaticQuery(
    graphql`
      query {
        mainBanner: file(relativePath: { eq: "banners/main-banner.jpg" }) {
          childImageSharp {
            fluid(base64Width: 1000, quality: 50) {
              base64
            }
          }
        }
      }
    `
  )

  useEffect(()=>{
    gtag('event', 'conversion', {'send_to': 'AW-672485658/fyT2CPjb2OQBEJqi1cAC'});
  },[])

  return (
    <Layout>
      <SEO title="Recebemos seu contato" />

      <Hero
        title="Recebemos o seu contato."
        description="Obrigado pela confiança.\nAgora é só aguardar que em breve entraremos em contato."
        fluidImage={mainBanner}
      />

      <LogoCompaniesSection />
    </Layout>
  )
}

export default Contato
